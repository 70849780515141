<template>
  <div class="customers">
    <div class="title_line flex justify-between">
      <div class="inline-block flex w-1/3 justify-between items-center">
        <h1 class="text-left">Drivers</h1>
      </div>

      <div class="inline-block flex w-2/3 justify-end button_group">
        <button class="text-white flex items-center button_text last_in_row_button full_green_button">
          <router-link to="/add-or-edit-driver/0" class="flex items-center">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12 4V19.9996" stroke="white" stroke-width="2" stroke-linecap="round"/>
              <path d="M20 12L4.00038 12" stroke="white" stroke-width="2" stroke-linecap="round"/>
            </svg>

            <span class="h-full">
            Add Driver
          </span>
          </router-link>
        </button>
      </div>
    </div>

    <div class="filters" v-if="drivers">
      <FiltersDop
          :min_filter="min_filter"
          :names="drivers_names"
          :selected_name="selected_name"
          :items_count="drivers_count"
          :rows_length="drivers.length"
          :rows_count="rows_count"
      ></FiltersDop>
    </div>

    <div class="customers_table fl_table mt-4 px-4">
      <div class="wrap" v-if="drivers">
        <!-- table header-->
        <div class="header py-3 px-2 flex text-left justify-between">
          <div class="item flex items-center justify-between w-2/12">
            <span class="strong_text">
              Driver name
            </span>
            <span class="sort"
                  @click="sortItems('name')"
                  :class="{
                    'hidden': !sort_names.name,
                    'rotate': ascending
                  }">
              <svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5.82406 6.80139C5.42671 7.37935 4.57332 7.37936 4.17597 6.80139L0.576992 1.56653C0.120833 0.903028 0.595854 8.78263e-08 1.40103 1.58217e-07L8.59897 -1.66193e-07C9.40415 -9.58021e-08 9.87917 0.903026 9.42302 1.56653L5.82406 6.80139Z" fill="black"/>
              </svg>
            </span>
          </div>

          <div class="item w-2/12">
            User name
          </div>

          <div class="item w-2/12">
            Registration date
          </div>

          <div class="item w-5/12">
            Address
          </div>

          <div class="item delete_column">
          </div>
        </div>

        <!-- table body -->
        <div class="body py-2">
          <div
              v-for="(driver, index) in drivers"
              :key="index" :class="checked_driver[index] ? 'checked_row' : ''"
              class="py-5 px-2 flex text-left justify-between">

            <div class="item flex items-center justify-start w-2/12" @click="editDriver(driver.id)">
              <div class="green_text icon_div">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-search">
                  <circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                </svg>
              </div>
              <div class="green_text pt-1 pl-1">
                {{ driver.name }}
              </div>
            </div>
            <div class="item flex items-center justify-between w-2/12">
              {{ driver.username }}
            </div>
            <div class="item flex items-center justify-between w-2/12">
              {{ driver.created_at | moment('DD-MM-YYYY') }}
            </div>
            <div class="item flex items-center justify-start w-5/12" v-if="driver.address1 || driver.address2">
              {{ driver.address1 }} <br v-if="driver.address2"/>
              {{ driver.address2 }}
            </div>
            <div class="item flex items-center justify-start w-5/12" v-else>
              No
            </div>

            <div class="item flex items-center justify-center w-44px">
              <button @click="deleteThisItem(driver.id)" class="flex items-center justify-center">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M8 4C8 2.89543 8.89543 2 10 2H14C15.1046 2 16 2.89543 16 4V6H8V4Z" stroke="#F2374D" stroke-width="2"/>
                  <path d="M3 6H21" stroke="#F2374D" stroke-width="2" stroke-linecap="round"/>
                  <path d="M6.33 20.1867L5 6H19L17.67 20.1867C17.5736 21.2144 16.711 22 15.6787 22H8.32127C7.28902 22 6.42635 21.2144 6.33 20.1867Z" stroke="#F2374D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M10 18L9.5 10" stroke="#F2374D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M14 18L14.5 10" stroke="#F2374D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <DeleteModal
        :entity="'driver'"
        v-on:delete-select="deleteSelect" v-on:delete-close="closeDelete" ref="delete_modal"/>

  </div>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import FiltersDop from '../../components/FiltersDop';
import {infoMessage} from "../../services/messages";
import DeleteModal from "@/components/DeleteModal";
import moment from "moment/moment";

export default {
  name: "Drivers",
  props: {
    rows_count: {
      type: Number,
      default: 10
    }
  },
  data() {
    return {
      min_filter: true,
      sort_names: {
        name: true,
      },
      drivers_names:[
        {
          name: 'Driver Name',
          id: 'name'
        },
      ],
      selected_name: {name: 'Driver Name', id: 'name'},
      user: null,
      checked_driver: [],
      checked_all: null,
      show_buttons: false,
      show_manage: false,
      delete_ids: [],
      file_items: [],
      ascending: false,
      drivers: [],
      drivers_count: null,
      delete_id: null,
    }
  },
  methods: {
    moment() {
      return moment
    },
    ...mapMutations([]),
    editDriver(id) {
      this.$router.push('/add-or-edit-driver/' + id);
    },
    async getUser() {
      this.user = JSON.parse(localStorage.getItem('user')) || null;
    },
    async sortItems(name) {
      const j = this.sort_names;
      this.checked_driver = [];
      this.checked_all = false;
      Object.keys(j).forEach(sort_name => {
        j[sort_name] = false;
      })
      this.ascending = !this.ascending;
      this.sort_names[name] = true
      await this.getListDrivers(name);
    },
    async checkedCloseCreate(val) {
      if (val) {
        infoMessage(
            `Driver created`,
            this.getTopBanner ? 'success open_top_banner' : 'success'
        );
      } else {
        infoMessage(
            'Oops. Something went wrong...',
            this.getTopBanner ? 'error open_top_banner' :'error'
        );
      }

      await this.getListDrivers();
    },
    async getListDrivers(sortBy = 'name') {
      try {
        const requestData = {
          skip: this.getRowsToShift || 0,
          take: this.getItemsToShow || 5,
          sort: sortBy,
          column: this.getSelectedFilterItem || 'name',
          search: this.getSearchItemsNames || '',
          ascending: this.ascending,
        }

        const response = await this.$http
            .getAuth(`${this.$http.apiUrl()}/drivers?skip=${requestData.skip}&take=${requestData.take}&sort-by=${requestData.sort}&search=${requestData.search}&sort=${requestData.ascending}`);

        this.drivers = response?.data?.drivers?.data || [];
        this.drivers_count = response?.data?.drivers?.count || null;
      } catch (e) {
        console.log(e);
      }
    },
    deleteThisItem(id) {
      this.delete_id = id;
      this.$refs.delete_modal.show = true;
    },
    closeDelete() {
      this.delete_id = null;
    },
    async deleteSelect() {
      try {
        await this.$http.deleteAuth(`${this.$http.apiUrl()}/drivers/${this.delete_id}/delete`);
      } catch (e) {
        console.log(e);

        this.delete_id = null;
        this.checked_driver = [];

        return infoMessage(
            'Oops. Something went wrong...',
            this.getTopBanner ? 'error open_top_banner' :'error'
        );
      }

      infoMessage(
          `Driver deleted`,
          this.getTopBanner ? 'success open_top_banner' : 'success'
      );
      this.delete_id = null;
      this.checked_driver = [];

      await this.getListDrivers();
    },
  },
  async mounted() {
    await this.getUser();
    await this.getListDrivers();
  },
  computed: {
    ...mapGetters([
      'getItemsToShow',
      'getSelectedFilterItem',
      'getRowsToShift',
      'getSearchItemsNames',
      'getTopBanner',
    ]),
  },
  components: {
    FiltersDop,
    DeleteModal,
  },
  watch: {
    file_items(val) {
      if (val && val.length) {
        this.pushList(val);
      }
    },
    getItemsToShow() {
      this.getListDrivers()
    },
    getSelectedFilterItem() {
      this.getListDrivers()
    },
    getRowsToShift() {
      this.getListDrivers()
    },
    getSearchItemsNames() {
      this.getListDrivers()
    },
  }
}
</script>

<style lang="scss" scoped>
.customers {
  min-height: calc(100vh - (147px + 18px));
}
.item {
  font-size: 14px;
  padding-right: 25px;
}
.actions {
  &:hover, &:active, &:focus{
    .hidden {
      display: block;
    }
    span {
      &>* {
        cursor: pointer;
      }
      &:first-child {
        &>*{
          margin-left: auto;
        }
      }
    }
  }
}
.title_line {
  padding: 30px 0 22px;
  border-bottom: none;
}
.filters {
  width: 95%;
  margin: auto;
}
.button_text {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
}
.in_row_button {
  margin: 0 15px;
  border-radius: 8px;
}
.last_in_row_button {
  margin-left: 15px;
  border-radius: 8px;
}
.square_button {
  padding: 10px;
  width: 44px;
  height: 44px;
  box-sizing: border-box;
}
.square_green_button {
  color: #1FBC2F;
  border: 2px solid #1FBC2F;
}
.square_red_button {
  color: #F2374D;
  border: 2px solid #F2374D;
  svg {
    path {
      stroke: #F2374D;
    }
  }
}
.square_red_button:disabled {
  color: #3d4852;
  border: 2px solid #3d4852;
  svg {
    path {
      stroke: #3d4852;
    }
  }
}
.strong_text {
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: #000000 !important;
}
.delete_column {
  width: 49px;
}
.item {
  font-size: 14px;
  span {
    margin-left: 5px;
    color: #969A9F;
  }
}
.green_text {
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
  color: #1FBC2F !important;
}
.text_black {
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
  color: #000000 !important;
}
.span_cut {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: black !important;
}
.checked_row {
  background-color: #FFF1CE;
}
.button_group {
  position: relative;
  .manage_group {
    z-index: 99;
    position: absolute;
    top: 48px;
    right: 0;
    border-radius: 8px;
    box-sizing: border-box;
    border: 0.5px solid #D8C3AF;
    box-shadow: 5px 5px rgba(216, 195, 175, 0.5);
    padding: 10px;
    background-color: #FFFFFF;
    button {
      margin: 10px;
    }
  }
}
.page {
  position: relative;
}
.blocked {
  position: absolute;
  top: 0;
  left: 0;
  min-height: 100vh;
  min-width: 100vw;
  height: 100%;
  width: 100%;
  z-index: 98;
}
.icon_div {
  width: 16px;
  svg {
    width: 100%;
  }
}
</style>
